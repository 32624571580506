import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailEditorComponent } from 'angular-email-editor';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmailMarketingService, EmailTemplate } from '../../@core/services/email-marketing.service';
import { UploadService } from '../../@core/services/upload.service';

@Component({
  selector: 'app-email-marketing',
  templateUrl: './email-marketing.component.html',
  styleUrls: ['./email-marketing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailMarketingComponent implements OnInit {
  @ViewChild(EmailEditorComponent)
  private emailEditor!: EmailEditorComponent;

  templates$: Observable<EmailTemplate[]>;
  currentTemplate?: EmailTemplate;
  templateForm: FormGroup;
  private destroy$ = new Subject<void>();

  editorLoaded = false;
  options: any;

  constructor(
    private emailService: EmailMarketingService,
    private uploadService: UploadService,
    private message: NzMessageService,
    private fb: FormBuilder
  ) {
    this.templates$ = this.emailService.getTemplates();
    this.templateForm = this.fb.group({
      name: ['', Validators.required]
    });

    this.options = {
      customFileUpload: this.onImageUpload,
      displayMode: 'email',
      locale: 'es',

      appearance: {
        theme: 'light',
        locale: 'es'
      },
      fonts: {
        showDefaultFonts: true,
        customFonts: [
          {
            label: 'Titillium Web',
            value: "'Titillium Web', sans-serif",
            url: 'https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;600;700&display=swap'
          },
          {
            label: 'Montserrat',
            value: "'Montserrat', sans-serif",
            url: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap'
          },
          {
            label: 'Roboto',
            value: "'Roboto', sans-serif",
            url: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap'
          },
          {
            label: 'Open Sans',
            value: "'Open Sans', sans-serif",
            url: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap'
          },
          {
            label: 'Lato',
            value: "'Lato', sans-serif",
            url: 'https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap'
          },
          {
            label: 'Poppins',
            value: "'Poppins', sans-serif",
            url: 'https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap'
          },
          {
            label: 'Raleway',
            value: "'Raleway', sans-serif",
            url: 'https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap'
          },
          {
            label: 'Playfair Display',
            value: "'Playfair Display', serif",
            url: 'https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap'
          }
        ]
      },
      defaultFont: {
        family: "'Open Sans', sans-serif",
        url: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap'
      },
      features: {
        imageEditor: true,
        textEditor: {
          spellChecker: true,
          tables: true,
          cleanPaste: true
        }
      },
      tools: {
        image: {
          properties: {
            src: {
              value: ''
            }
          }
        }
      },
      translations: {

        'es': {
          'widget.image.empty': 'Haga clic para agregar una imagen',
          'widget.menu.align': 'Alinear',
          'widget.image.replace': 'Reemplazar',
          'widget.image.width': 'Ancho',
          'widget.image.height': 'Alto',
          'widget.image.constrain': 'Mantener proporción',
          'widget.image.link': 'Enlace',
          'widget.image.alt': 'Texto alternativo',
          'widget.image.title': 'Título',
          'actions.remove': 'Eliminar',
          'actions.save': 'Guardar',
          'actions.cancel': 'Cancelar',
          'tools.text': 'Texto',
          'tools.image': 'Imagen',
          'tools.button': 'Botón',
          'tools.divider': 'Divisor',
          'tools.spacer': 'Espaciador',
          'tools.social': 'Social',
          'tools.video': 'Video',
          'tools.html': 'HTML'

        }
      }
    };
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onEditorLoaded() {
    this.editorLoaded = true;
    this.emailEditor.editor.setLocale('es')
    this.emailEditor.editor.setLanguages([
      {
        label: 'Español',
        value: 'es'
      }
    ])
    console.log(this.emailEditor)
    if (this.currentTemplate?.design) {
      setTimeout(() => {
        this.emailEditor.loadDesign(this.currentTemplate!.design);
      }, 100);
    }
  }

  onSave(): void {
    if (this.templateForm.invalid) {
      this.message.error('Please provide a template name');
      return;
    }

    this.emailEditor.exportHtml((data) => {
      const template: Partial<EmailTemplate> = {
        name: this.templateForm.get('name')?.value,
        design: data.design, // This contains the full design JSON
        ...(this.currentTemplate?.id ? { id: this.currentTemplate.id } : {})
      };

      this.emailService.saveTemplate(template)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => this.message.success('Template saved successfully'),
          error => this.message.error('Error saving template')
        );
    });
  }

  onImageUpload = async (file: File): Promise<string> => {
    try {
      return await this.uploadService.uploadImage(file, 'email-templates').toPromise();
    } catch (error) {
      this.message.error('Error uploading image');
      throw error;
    }
  }
}
