import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

export interface EmailTemplate {
  id?: string;
  name: string;
  design: any;
  createdAt: Date;
  updatedAt: Date;
}

@Injectable({
  providedIn: 'root'
})
export class EmailMarketingService {
  constructor(private firestore: AngularFirestore) { }

  saveTemplate(template: Partial<EmailTemplate>): Observable<string> {
    const templateData = {
      ...template,
      updatedAt: new Date(),
      createdAt: template.createdAt || new Date()
    };

    if (template.id) {
      return from(this.firestore.doc(`emailTemplates/${template.id}`).update(templateData))
        .pipe(map(() => template.id!));
    } else {
      return from(this.firestore.collection('emailTemplates').add(templateData))
        .pipe(map(ref => ref.id));
    }
  }

  getTemplates(): Observable<EmailTemplate[]> {
    return this.firestore.collection<EmailTemplate>('emailTemplates', ref =>
      ref.orderBy('createdAt', 'desc')
    ).valueChanges({ idField: 'id' });
  }

  getTemplate(id: string): Observable<EmailTemplate> {
    return this.firestore.doc<EmailTemplate>(`emailTemplates/${id}`)
      .valueChanges({ idField: 'id' }) as Observable<EmailTemplate>;
  }

  deleteTemplate(id: string): Observable<void> {
    return from(this.firestore.doc(`emailTemplates/${id}`).delete());
  }
}
