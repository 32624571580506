<div class="email-marketing-container">
    <div class="toolbar">
        <form [formGroup]="templateForm" class="template-form">
            <nz-input-group [nzAddOnBefore]="'Template Name'">
                <input nz-input formControlName="name" placeholder="Enter template name" />
            </nz-input-group>
        </form>

        <button nz-button nzType="primary" (click)="onSave()" [disabled]="templateForm.invalid">
            Save Template
        </button>
    </div>

    <div class="editor-container">
        <email-editor [locale]="'es'" [options]="options" (loaded)="onEditorLoaded()">
        </email-editor>
    </div>
</div>