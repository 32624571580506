import { NgModule } from '@angular/core';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';

import {
  CalendarOutline,
  ClockCircleOutline,
  DashboardOutline,
  DeleteFill,
  DeleteOutline,
  EditOutline,
  FileExcelOutline,
  FileImageOutline,
  FileSearchOutline,
  FormOutline,
  HomeOutline,
  InfoCircleOutline,
  MailOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  MoreOutline,
  OrderedListOutline,
  SettingFill,
  SettingOutline,
  SolutionOutline,
  UserOutline
} from '@ant-design/icons-angular/icons';

const icons = [
  SolutionOutline,
  MenuFoldOutline,
  UserOutline,
  ClockCircleOutline,
  SettingFill,
  FileSearchOutline,
  MoreOutline,
  OrderedListOutline,
  FileExcelOutline,
  CalendarOutline,
  HomeOutline,
  InfoCircleOutline,
  MenuUnfoldOutline,
  DashboardOutline,
  FormOutline,
  SettingOutline,
  DeleteOutline,
  FileImageOutline,
  DeleteFill,
  EditOutline,
  MailOutline,
];

@NgModule({
  imports: [NzIconModule],
  exports: [NzIconModule],
  providers: [{ provide: NZ_ICONS, useValue: icons }],
})
export class IconsProviderModule { }
